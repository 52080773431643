<template>
  <HomescreenShortcut :visible="dialogVisible" @update:visible="dialogVisible = $event" />
  <h2>Heutige Aufgüsse!</h2>
  <div class="container-kurse">
    <div class="no-kurse-message" v-if="infusions.length === 0">Es finden heute keine Aufgüsse statt.</div>
    <div v-else>
      <div v-for="(infusion, index) in infusions" :key="infusion.id">
        <InfusionCard :starttime="infusion.starttime" :name="`${index + 1}. ${infusion.name}`" :info="infusion.info" />
      </div>
    </div>
  </div>
  <Button class="fixed-button" label="WebApp" icon="pi pi-desktop" rounded outlined @click="dialogVisible = true" />
</template>

<script setup>
import { ref } from "vue";
import { BACKEND_BASE_URL } from "@/config";

const infusions = ref([]);

const fetchData = () => {
  const url = `${BACKEND_BASE_URL}/api/getInfusions.php`;
  console.log("requesting infusions at: ", url);
  fetch(url, {
    method: 'GET',
    cache: 'no-store'
  })
    .then(response => {
      console.log('Response:', response);
      return response.text();
    })
    .then(text => {
      console.log('Response text:', text);
      return JSON.parse(text);
    })
    .then(data => {
      if (('error' in data)) {
        infusions.value = [];
        console.error('Fehler beim Laden der Daten:', data.error);
      } else if ('warning' in data) {
        infusions.value = [];
        console.log('Es konnten keine Daten geladen werden:', data.warning);
      } else {
        infusions.value = data;
      }
    })
    .catch(error => console.error('Fehler beim Laden der Daten:', error));
};


// Initialer API-Aufruf
fetchData();

const dialogVisible = ref(false);
</script>

<script>
import HomescreenShortcut from "@/components/HomescreenShortcut.vue";
import Button from "primevue/button";
import InfusionCard from "@/components/InfusionCard.vue";

export default {
  name: 'CourseView',
  components: {
    HomescreenShortcut,
    Button,
    InfusionCard,
  }
}
</script>

<style scoped>
h2 {
  font-size: 40px;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
}

.container-kurse {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 150px;
}

.no-kurse-message {
  font-size: 20px;
  padding-top: 20px;
  text-align: center;
}

.fixed-button {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  left: auto;
}

@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
  h2 {
    font-size: 30px;
  }

  .fixed-button {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }
}
</style>