<template>
    <div class="card">
        <TabMenu :model="items" v-model:active-index="activeIndex">
            <template #item="{ item, props }">
                <router-link v-if="item.route" v-slot="{ href, navigate, isActive }" :to="item.route" exact>
                    <a v-ripple :href="href" v-bind="props.action" @click="navigate"
                        :class="{ 'p-menuitem-link-active': isActive }">
                        <span v-bind="props.icon" />
                        <span v-bind="props.label">{{ item.label }}</span>
                    </a>
                </router-link>
                <a v-else v-ripple :href="item.url" :target="item.target" v-bind="props.action">
                    <span v-bind="props.icon" />
                    <span v-bind="props.label">{{ item.label }}</span>
                </a>
            </template>
        </TabMenu>
    </div>
</template>


<script setup>
import TabMenu from 'primevue/tabmenu';
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from 'vue-router';

const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    router.push('/admin/login');
};

const user = JSON.parse(localStorage.getItem('user'));
const role = user ? user.role : '';

const items = ref([
    { label: 'Kursverwaltung', icon: 'pi pi-clock', route: '/admin/kurse' },
    { label: 'Aufgussverwaltung', icon: 'pi pi-clock', route: '/admin/aufguss' },
    ...(role === 'admin'
        ? [{ label: 'Benutzerverwaltung', icon: 'pi pi-user', route: '/admin/users' }]
        : []),
        { label: 'Logout', icon: 'pi pi-sign-out', command: logout }
]);


const route = useRoute();
const router = useRouter();
const activeIndex = ref(null);

const setActiveTab = () => {
    activeIndex.value = items.value.findIndex(item => item.route === route.path);
};

watch(route, () => {
    setActiveTab();
});

onMounted(() => {
    setActiveTab();
});


</script>
