<template>
    <div class="kurse">
        <h2>{{ timeStart }} - {{ timeEnd }} Uhr</h2>
        <div>
            <h3>{{ name }}</h3>
            <Button class="mt-4 w-2rem h-2rem" icon="pi pi-info" rounded outlined aria-label="Filter"
                @click="showKursinfo = true" />
        </div>
        <h4>{{ cancelled ? 'Kurs findet heute leider nicht statt.' : '' }}</h4>
        <Dialog v-model:visible="showKursinfo" :style="{ width: '50rem' }">
            <template #header>
                <div class="block w-full">
                    <h2>{{ name }}</h2>
                    <Divider />
                </div>
            </template>
            <span style="font-size: 17px; line-height: 150%;">{{ info }}</span>
        </Dialog>
    </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Divider from 'primevue/divider';

export default {
    name: 'KursCard',
    components: {
        Dialog,
        Button,
        Divider,
    },
    props: {
        timeStart: {
            type: String,
            required: true,
        },
        timeEnd: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        cancelled: {
            type: Boolean,
            default: false,
        },
        info: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            showKursinfo: false,
        }
    }
}
</script>

<style scoped>
.kurse {
    text-align: center;
    border-bottom: solid 2px;
    padding-top: 25px;
    padding-bottom: 10px;
}

h2 {
    font-size: 23px;
    padding-bottom: 5px;
}

h3 {
    font-size: 30px;
    line-height: 35px;
    margin: 0;
}

h4 {
    color: red;
}
</style>