<template>
    <div class="kurse">
        <h3>{{ starttime }} Uhr</h3>
        <!-- <h2>{{ name }}</h2> -->
        <p class="text-xl">{{ info }}</p>
    </div>
</template>

<script>

export default {
    name: 'KursCard',
    components: {},
    props: {
        name: {
            type: String,
            required: false,
        },
        date: {
            type: String,
            required: false,
        },
        starttime: {
            type: String,
            required: true,
        },
        info: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            showInfo: false,
        }
    }
}
</script>

<style scoped>
.kurse {
    text-align: center;
    border-bottom: solid 2px;
    padding-top: 25px;
    padding-bottom: 10px;
}

h2 {
    font-size: 23px;
    padding-bottom: 5px;
}

h3 {
    font-size: 30px;
    line-height: 25px;
    margin: 0;
}
</style>